//./components/Monster-Card.js
import React, {useState, useEffect, useRef }from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  CardFooter,
  Col
} from "reactstrap";
import { format } from "date-fns";
import EntradaTooltip from "./EntradaToolTip";
import LineChartComponent from "./LineChartComponent";
import axios from "axios";


const MonsterCard = ({ monster }) => {
    const mapeamentoQ = {
        0: 'Comum',
        1: 'Incomum',
        2: 'Raro',
        3: 'Lendário',
        4: 'Épico',
        5: 'Especial'
      };
    const mapeamentoC = {
        0: '#6C757D',
        1: '#2F3F18',
        2: '#1E2F5C',
        3: '#3E1F58',
        4: '#5F4E12',
        5: '#611211'
      };
    const mapeamentoCC = {
        0: '#FFFFFF',
        1: '#FFFFFF',
        2: '#FFFFFF',
        3: '#cf8c30',
        4: '#cf8c30',
        5: '#cf8c30'
      };
  
  useEffect(() => {
    
  });

  return (

    <Card className="my-2 p-0" inverse style={{ width: '11rem', margin: 5, backgroundColor: mapeamentoC[monster.quality] }}>
        <CardHeader>Monster {mapeamentoQ[monster.quality]}</CardHeader>
        <CardBody style={{padding: "0"}}>
        <CardText style={{fontSize: "1em", fontWeight: "bold", display: 'flex'}}>
            <Col style={{ flex: 1, paddingTop: 5, paddingBottom: 5, paddingLeft: 5}}>
            Familia. {monster.familyid}
            </Col>
            <Col style={{ flex: 1, paddingTop: 5, paddingBottom: 5, textAlign:'right' }}>
            Lvl. {monster.lvl}
            </Col>
        </CardText>
        <CardText style={{fontSize: "1.5em", fontWeight: "bold", backgroundColor: "#00000055", borderRadius: "25px", display: 'flex', marginLeft: 15, marginRight: 15, marginBottom:15}}>
            <Col style={{ flex: 1 }}>
            ⛏️
            </Col>
            <Col style={{ flex: 1, color: mapeamentoCC[monster.quality] }}>
            {monster.hash}
            </Col>
        </CardText>
        </CardBody>
    </Card>

  );
};

export default MonsterCard;